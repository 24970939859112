html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;

  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  text-shadow: #fff 0px 1px 1px;

  font-family: "Rubik", sans-serif;
  color: #2f3542;
}

body {
  margin: 0;
  padding: 0;
}

#container {
  width: 100vw;
  height: 100vh;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  > #logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 500px;
    max-width: 85vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    > #icon {
      width: 250px;
      height: 250px;
    }

    > #title {
      font-weight: 500;
      font-size: 25px;
      margin: 0 0 15px 0;
    }

    > #subtitle {
      font-weight: 300;
      text-align: center;
      font-size: 15px;
      color: #57606f;
    }

    > #link {
      margin: 30px 0;
    }
  }

  > #footer {
    width: 100%;
    height: 30px;

    background-color: #f1f2f6;

    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      font-size: 12px;
      font-weight: 300;
      color: #57606f;

      margin: 0 10px;
      text-decoration: none;
    }
  }
}
